import React, { useEffect, useState } from 'react';
import {Form} from "react-bootstrap";
import { nflTeams } from '../../../utils/nflUtils';
const NflStatAvgs = (props) => {
    const game = props.game;
    const home_team = nflTeams[game.home.team_id];
    const away_team = nflTeams[game.away.team_id];

    const [genOffStats, setGenOffStats] = useState(null);
    const [rushOffStats, setRushOffStats] = useState(null);
    const [passOffStats, setPassOffStats] = useState(null);
    const [tackDefStats, setTackDefStats] = useState(null);
    const [passDefStats, setPassDefStats] = useState(null);
    const [activeTab, setActiveTab] = useState('genOff');
    const genOffLabels = {
        "first_downs":"First Downs",
        "total_yds":"Total Yards",
        "fumbles":"Fumbles",
        "fumbles_lost": "Fumbles Lost",
        "turnovers":"Turnovers",
        "possession":"Possession (Mins)"
    };
    const rushOffLabels = {
        "att":"Attempts",
        "yds":"Yards",
        "tds":"Touchdowns",
        "long":"Long",
        "ybc":"Yards before contact",
        "yac":"Yards after contact",
        "broken_tackles":"Broken tackles",
        "first_downs":"First Downs"
    };
    const passOffLabels = {
        "att":"Attempts",
        "comp":"Completions",
        "yds":"Yards",
        "tds":"Touchdowns",
        "ints":"Interceptions",
        "net_yds":"Net yards",
        "pass_rating":"Pass rating",
        "long":"Long",
        "first_downs":"First Downs",
        "intended_air_yds":"Intended air yards",
        "comp_air_yds":"Completed air yards",
        "yac":"Yards after catch",
        "poor_throws":"Poor throws",
        "blitzed":"Times blitzed",
        "hurried":"QB hurried",
        "hits_taken":"QB hits taken",
        "pressured":"QB pressured",
        "scrambles":"Scrambles",
        "scramble_yds":"Scramble yards",
        "dropbacks":"Dropbacks",
        "spikes_throwaways":"Spikes / Throwaways",
        "taken": "Sacks taken",
        "yds_lost": "Sack yards lost"
    };

    const passDefLabels = {
        "ints":"Interceptions",
        "int_yds":"Interception yards",
        "int_tds":"Pick 6s",
        "int_long":"Longest return",
        "passes_defended":"Passes defended",
    }
    const tackDefLabels = {
        "sacks":"Sacks",
        "solo":"Solo tackles",
        "assists":"Assisted tackles",
        "for_loss":"Tackles for loss",
        "qb_hits":"QB hits",
        "recovered":"Fumbles recovered",
        "forced":"Fumbles forced",
        "tds":"Fumble touchdown returns",
        "yds_ret":"Fumble yards returned",
        "player_blitzes":"Players Blitzed",
        "player_hurries":"QB hurries",
        "qb_knockdowns":"QB knockdowns",
        "player_pressures":"QB pressures",
        "missed_tackles":"Missed tackles"
    };

    const renderOffPassRow = (stat) =>{
        if (stat !== "taken" && stat !== "yds_lost") {
            return (
                <tr>
                    <td>{passOffLabels[stat]}</td>
                    <td>{game.home.off.passing[stat]}</td>
                    <td>{game.away.off.passing[stat]}</td>
                </tr>
            );
        }else{
            return (
                <tr>
                    <td>{passOffLabels[stat]}</td>
                    <td>{game.home.off.sacks[stat]}</td>
                    <td>{game.away.off.sacks[stat]}</td>
                </tr>
            );
        }
    };

    const renderDefTackRow = (stat) =>{
        if (stat === "recovered" || stat === "tds" || stat === "yds_ret" || stat === "forced") {
            return (
                <tr>
                    <td>{tackDefLabels[stat]}</td>
                    <td>{game.home.def.fumbles[stat]}</td>
                    <td>{game.away.def.fumbles[stat]}</td>
                </tr>
            );
        }else if(stat === "player_blitzes" || stat === "player_hurries" || stat === "qb_knockdowns" || stat === "player_pressures" || stat === "missed_tackles"){
            return (
                <tr>
                    <td>{tackDefLabels[stat]}</td>
                    <td>{game.home.def[stat]}</td>
                    <td>{game.away.def[stat]}</td>
                </tr>
            );
        }else{
            return (
                <tr>
                    <td>{tackDefLabels[stat]}</td>
                    <td>{game.home.def.tackles[stat]}</td>
                    <td>{game.away.def.tackles[stat]}</td>
                </tr>
            );
        };
    };

    useEffect(()=>{
        if('off' in game.home && 'off' in game.away){
            const genOffKeys = Object.keys(game.home.off)
                .filter(key => typeof game.home.off[key] !== 'object')
                .map(key => key);
            const rushOffKeys = Object.keys(game.home.off.rushing);
            let passOffKeys = Object.keys(game.home.off.passing);
            let sackOffKeys = Object.keys(game.home.off.sacks);
            let tmpGenOffStats = {}, tmpRushOffStats = {}, tmpPassOffStats = {}, tmpPassDefStats = {}, tmpTackDefStats = {}
            const passDefKeys = Object.keys(game.home.def.passing);
            const tackDefKeys = Object.keys(game.home.def.tackles);
            const fumbDefKeys = Object.keys(game.home.def.fumbles);
            const genDefKeys = Object.keys(game.home.def)
                .filter(key => typeof game.home.def[key] !== 'object')
                .map(key => key);
            // get general offensive stats
            genOffKeys.forEach(stat=>{
                tmpGenOffStats[stat] = [game.home.off[stat], game.away.off[stat]]
            });
            // get offensive passing stats
            passOffKeys.forEach(stat=>{
                tmpPassOffStats[stat] = [game.home.off.passing[stat], game.away.off.passing[stat]]
            });
            sackOffKeys.forEach(stat=>{
                tmpPassOffStats[stat] = [game.home.off.sacks[stat], game.away.off.sacks[stat]]
            });
            // get offensive rushing stats 
            rushOffKeys.forEach(stat=>{
                tmpRushOffStats[stat] = [game.home.off.rushing[stat], game.away.off.rushing[stat]]
            });
            // get defensive passing stats
            passDefKeys.forEach(stat=>{
                tmpPassDefStats[stat] = [game.home.def.passing[stat], game.away.def.passing[stat]]
            });
            // get defensive tackling stats
            tackDefKeys.forEach(stat=>{
                tmpTackDefStats[stat] = [game.home.def.tackles[stat], game.away.def.tackles[stat]]
            });
            fumbDefKeys.forEach(stat=>{
                tmpTackDefStats[stat] = [game.home.def.fumbles[stat], game.away.def.fumbles[stat]]
            });
            genDefKeys.forEach(stat=>{
                tmpTackDefStats[stat] = [game.home.def[stat], game.away.def[stat]]
            });
            // set stats
            setGenOffStats(tmpGenOffStats);
            setRushOffStats(tmpRushOffStats);
            setPassOffStats(tmpPassOffStats);
            setPassDefStats(tmpPassDefStats);
            setTackDefStats(tmpTackDefStats);
        };
    }, [props]);

    
    return(
        <>
            <Form.Select id="bet-select" value={activeTab} onChange={e => setActiveTab(e.target.value)}>
                <option value="genOff">General Offense</option>
                <option value="rushOff">Offensive Rushing</option>
                <option value="passOff">Offensive Passing</option>
                <option value="tackDef">Defensive Tackling</option>
                <option value="passDef">Defensive Passing</option>
            </Form.Select>
            {activeTab === "genOff" & genOffStats !== null ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{home_team}</th>
                            <th>{away_team}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(genOffStats).map(stat=>
                            (
                                <tr>
                                    <td>{genOffLabels[stat]}</td>
                                    <td>{game.home.off[stat]}</td>
                                    <td>{game.away.off[stat]}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
                ): null
            }
            {activeTab === "passOff" & passOffStats !== null ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{home_team}</th>
                            <th>{away_team}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(passOffStats).map(stat=> renderOffPassRow(stat))}
                    </tbody>
                </table>
                ): null
            }
            {activeTab === "rushOff" & passOffStats !== null ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{home_team}</th>
                            <th>{away_team}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(rushOffStats).map(stat=>
                        (
                            <tr>
                                <td>{rushOffLabels[stat]}</td>
                                <td>{game.home.off.rushing[stat]}</td>
                                <td>{game.away.off.rushing[stat]}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
                ): null
            }
            {activeTab === "tackDef" & tackDefStats !== undefined ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{home_team}</th>
                            <th>{away_team}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(tackDefStats).map(stat=> renderDefTackRow(stat))}
                    </tbody>
                </table>
                ): null
            }
            {activeTab === "passDef" & tackDefStats !== undefined ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{home_team}</th>
                            <th>{away_team}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(passDefStats).map(stat=>
                        (
                            <tr>
                                <td>{passDefLabels[stat]}</td>
                                <td>{game.home.def.passing[stat]}</td>
                                <td>{game.away.def.passing[stat]}</td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
                ): null
            }
        </>
    )
   

};

export default NflStatAvgs;